import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"

const Bowl = () => (
  <Layout>
    <SEO title="Bowl" />
    <div className="cardsContainer cardsContainer--bowl">
        <Cards postCount={2} hero={false} />
    </div>
  </Layout>
)

export default Bowl
